@use './Navbar.scss';
@use './Editor.scss';
@use './Preview.scss';

.App {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    height: 100%;
    width: 100%;
}

@media screen and (max-width: 620px) {
    .App {
        display: block;
    }
}

// @media screen and (min-width: 600px) {
//     .App {
//         height: calc(100vh - 64px);
//     }
// }

// @media screen and (max-width: 600px) {
//     .App {
//         height: calc(100vh - 56px);
//     }
// }
