@use './partials/reset';
@use './partials/variables';

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-stack-regular);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    code {
        font-family: var(--font-stack-monospace);
        font-size: 80%;
    }
}
