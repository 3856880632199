@use './partials/mixins';
@use './partials/preview-content';
@use './partials/mdc_font_reset';

$_header_height_min_600: 64px;
$_header_height_max_600: 56px;
$_preview_padding: 15px;

.preview {
    display: block;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.5px;
    padding: $_preview_padding;
    height: 100vh;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;

    &__empty {
        @include mixins.flex(column, null, center);

        text-align: center;
        background-color: white;
        opacity: 1;
        animation: fade-in;
        animation-iteration-count: 1;
        animation-timing-function: ease;
        animation-duration: 0.55s;
    }

    &__desktop,
    &__mobile {
        @include mixins.flex(column, null, null);
    }

    &__mobile {
        box-sizing: border-box;
        position: fixed;
        z-index: 10;
        top: $_header_height_max_600;
        background-color: white;
        height: 100%;
        width: 100%;
    }

    &__display_none {
        display: none;
    }

    &__fade {
        &_in {
            animation: fade-in;
            animation-iteration-count: 1;
            animation-timing-function: ease;
            animation-duration: 0.55s;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 620px) {
    .preview {
        &__empty {
            position: fixed;
            z-index: 10;
            top: $_header_height_max_600;
        }
    }
}

@media screen and (min-width: 600px) {
    .preview {
        height: calc(100vh - #{$_header_height_min_600});
    }
}

@media screen and (max-width: 600px) {
    .preview {
        height: calc(100vh - #{$_header_height_max_600});
    }
}
