$_header_height_max_600: 56px;
$_header_height_min_600: 64px;

#editor {
    font-family: 'Roboto Mono', 'IBM Plex Mono', 'Source Code Pro';
    font-weight: normal;
}

@media screen and (min-width: 600px) {
    #editor {
        height: calc(100vh - #{$_header_height_min_600}) !important;
    }
}

@media screen and (max-width: 600px) {
    #editor {
        height: calc(100vh - 56px) !important;
    }
}
